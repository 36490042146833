
import React, { useState, useEffect } from 'react';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
import { DataService } from "../dal"
import { IPatternsData, IPattern, IFilter } from "../dal/models"

interface IContentProps{
    filter: IFilter;
    visible: boolean;
}
export const  Content:React.FunctionComponent<IContentProps> =  ({filter, visible}) =>{
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(true);
    const [errrorMsg, setErrrorMsg] = useState(true);
    const [imgData, setImgData] = useState<IPatternsData>({Data:[], Datalength:0});
    const [currentPage, setCurrentPage] = useState<string>();
    const [currentPageIndx, setCurrentPageIndx] = useState<number>(0);
    const pageSize = 15;

    useEffect(() => {
        if(Object.keys(filter).length > 0){
            loadPatterns(filter);
         }
    }, [filter.ID]);

    useEffect(() => {
        const firstPic = currentPageIndx * pageSize;
        const pageRange = `${firstPic}_${firstPic + (pageSize-1)}`;
        filter["IndexRange"] = [pageRange];
        loadPatterns(filter);
    }, [currentPageIndx]);


    const loadPatterns = (f:IFilter)=> {
        setIsLoading(true);
        setHasError(false);
        const dataService = new DataService();
        dataService.getPatterns(f).then((response: IPatternsData)=>{
            setImgData(response);
            setCurrentPage(`Page ${currentPageIndx + 1}, ${currentPageIndx * pageSize + 1} - ${Math.min( (currentPageIndx + 1) * pageSize, response.Datalength)} of ${response.Datalength} records`);
            setIsLoading(false);
        }).finally(()=>{
            setIsLoading(false);
        }).catch((ex)=>{
            setErrrorMsg(ex.message)
            setHasError(true);
        });
    }

    const loadPage = (pageIndex:number) => {
        setCurrentPageIndx(Math.max(0, pageIndex));
    }

    return (<>
        { visible ? 
            isLoading ? 
                <ProgressIndicator label="Loading..." description=""/> : 
                <>
                    <a style={{cursor:"pointer", color:"blue"}} className="prev-button" onClick={(event) => loadPage(currentPageIndx-1)} hidden={currentPageIndx <= 0}>Prev</a>
                    <span style={{padding:"5px", fontWeight:"normal"}} >{currentPage}</span>
                    <a style={{cursor:"pointer", color:"blue"}} className={"next-button"} onClick={(event) => loadPage(currentPageIndx+1)} hidden={(currentPageIndx + 1) * pageSize >= imgData!.Datalength} >Next</a>
                    {
                        hasError && <p style={{color:"red"}}> {errrorMsg}<br/></p>
                    }
                    {
                        (imgData!.ErrorMessage !== "") && <p style={{color:"red"}}>{imgData!.ErrorMessage}</p>
                    }
                    <table>
                    {
                        imgData!.Data.map((img:IPattern, i:number) =>{
                            return <tbody key={i}>
                                <tr>
                                    <th colSpan={4}> <a target='_blank' href={'https://finviz.com/quote.ashx?t=' + img.Ticker}> {img.Company}</a></th>
                                </tr>
                                <tr key={-i}>
                                    <td colSpan={4}><img style={{height:"325px"}} alt={img.Company} title={img.Company} src={"data:image/bmp;base64,"+img.Image}/></td> 
                                </tr>
                            </tbody>
                        })
                    }
                    </table>
                    {
                        imgData!.Datalength == 0 && <p style={{color:"red"}}>No result</p>
                    }
                </> :
            <></>
        }
    </>);
};

export default Content;