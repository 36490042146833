import axios from "axios";
import { IDictionary, IAuthData, IPatternsData, IFilterData, IFilter, IVolatilityData, IRobotData, IRobotGroupData } from "./models"
import  Helper  from './helper'

export default  class DataService {
    private apiServerURL =  "https://vmotorin.ru/api2/";

    public getFiltersData():Promise<IFilterData>{
        return new Promise<IFilterData>((resolve, reject) => {
            const params = new URLSearchParams();
            params.append("culture", "en-us");
            axios.post<IDictionary, {data: IFilterData}>(this.apiServerURL + "Dictionary", params)
            .then((data)=>{
                resolve(data.data);
            })
            .catch((error)=>{
                console.error(error);
                reject(error);
            });  
        });
    }

    public getPatterns(filter: IFilter):Promise<IPatternsData> {
        filter.Auth = Helper.GetAuthData();
        return new Promise<IPatternsData>((resolve, reject) => {
            axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
            axios.post<IDictionary, {data: IPatternsData}>(this.apiServerURL + "FilteredPatterns", filter)
            .then((data)=>{
                Helper.SetAuthData(data.data.Auth!);
                resolve(data.data);
            })
            .catch((error)=> {
                console.error(error);
                reject(error);
            });  
        });
    }

    public getVolatility():Promise<IVolatilityData[]> {
        return new Promise<IVolatilityData[]>((resolve, reject) => {
            axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
            axios.post<IDictionary, {data: IVolatilityData[]}>(this.apiServerURL + "Volatility", {utc: (new Date()).getTimezoneOffset()})
            .then((data)=>{
                resolve(data.data);
            })
            .catch((error)=> {
                console.error(error);
                reject(error);
            });  
        });
    }

    public getFinamStatus():Promise<string> {
        return new Promise<string>((resolve, reject) => {
            axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
            axios.post<IDictionary, {data: string}>(this.apiServerURL + "FinamStatus", {utc: (new Date()).getTimezoneOffset()})
            .then((data)=>{
                resolve(data.data);
            })
            .catch((error)=> {
                console.error(error);
                reject(error);
            });  
        });
    }

    public getRobotReport(startDay?:string):Promise<IRobotData[]> {
        return new Promise<IRobotData[]>((resolve, reject) => {
            axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
            axios.post<IDictionary, {data: IRobotData[]}>(this.apiServerURL + "GetRobotReport", {startDay:startDay || '', utc: (new Date()).getTimezoneOffset()})
            .then((data)=>{
                resolve(data.data);
            })
            .catch((error)=> {
                console.error(error);
                reject(error);
            });  
        });
    }

    public getRobotGroupReport(startDay?:string):Promise<IRobotGroupData[]> {
        return new Promise<IRobotGroupData[]>((resolve, reject) => {
            axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
            axios.post<IDictionary, {data: IRobotGroupData[]}>(this.apiServerURL + "GetRobotGroupReport", {startDay:startDay || '', utc: (new Date()).getTimezoneOffset()})
            .then((data)=>{
                resolve(data.data);
            })
            .catch((error)=> {
                console.error(error);
                reject(error);
            });  
        });
    }

    public SignIn(login:string, pswd: string):Promise<IAuthData> {
        return new Promise<IAuthData>((resolve, reject) => {
            const browser = Helper.GetAuthData().Browser;
            axios.post<IDictionary, {data: IAuthData}>(this.apiServerURL + "SignIn", {login, pswd, browser})
            .then((data)=>{
                Helper.SetAuthData(data.data);
                resolve(data.data);
            })
            .catch((error)=>{
                console.error(error);
                reject(error);
            }); 
        })
    }

    public SignUp(login:string, pswd: string, captcha:string ):Promise<IAuthData> {
        return new Promise<IAuthData>((resolve, reject) => {
            const browser = Helper.GetAuthData().Browser;
            axios.post<IDictionary, {data: IAuthData}>(this.apiServerURL + "SignUp", {login, pswd, browser, captcha})
            .then((data)=>{
                Helper.SetAuthData(data.data);
                resolve(data.data);
            })
            .catch((error)=>{
                console.error(error);
                reject(error);
            }); 
        })
    }

    public SignOut():Promise<IAuthData> {
        return new Promise<IAuthData>((resolve, reject) => {
            const data = Helper.GetAuthData();
            axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
            axios.post<IDictionary, {data: IAuthData}>(this.apiServerURL + "SignOut", {Browser:data.Browser})
            .then((data)=>{
                Helper.SetAuthData(data.data);
                resolve(data.data);
            })
            .catch((error)=>{
                console.error(error);
                reject(error);
            }); 
        })
    }

    public Captcha():Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const data = Helper.GetAuthData();
            axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
            axios.post<IDictionary, {data: string}>(this.apiServerURL + "Captcha", {Browser:data.Browser})
            .then((data)=>{
                resolve(data.data);
            })
            .catch((error)=>{
                console.error(error);
                reject(error);
            }); 
        })
    }
}
