import React, { useState, useEffect } from 'react';
import { DataService } from "../dal"
import { Panel, PanelType, Label, PrimaryButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';

interface IAboutProps {
    callBack: (tab:number) => void;
    open:boolean;
    callBackTab:number;
}

export const  About:React.FunctionComponent<IAboutProps> =  ({callBack, open, callBackTab}) =>{
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);

    useEffect(() => {
        if (open && !isOpen) {
            openPanel();
        }
    }, [open]);

    const close = ()=> { 
        dismissPanel();
        callBack(callBackTab);
    }

    return (
        <Panel
            headerText="Contact us"
            isOpen={isOpen}
            closeButtonAriaLabel=""
            isLightDismiss={true}
            isBlocking={true}
            type={PanelType.custom}
            customWidth={'400px'}
            onDismiss={close}>
                <p>Telegram bot: <a target='_blank' href='https://t.me/InSignumBot'> @InSignumBot </a></p>
                <p>Telegram channel: <a target='_blank' href='https://t.me/in_signum'> @in_signum </a></p>
                <p>E-mail: <a href="mailto:insignum.w@gmail.com">insignum.w@gmail.com</a> </p>
                <p>Phone:</p>
        </Panel>
    )
};

export default About;