import React, { useState, useEffect } from 'react';
import { DataService } from "../dal"
import { IVolatilityData, IVolatility } from "../dal/models";
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';

interface IVolatilityProps {
    visible: boolean;
}

export const  Volatility:React.FunctionComponent<IVolatilityProps> =  ({visible}) =>{
    const updateInterval = 60;
    let timer: any = null;
    const [tableData, setTableData] = useState<IVolatilityData[]>([{timeFrame:"M5", volatility:[]},{timeFrame:"M15", volatility:[]},{timeFrame:"H1", volatility:[]}]);
    const [finamStatus, setFinamStatus] = React.useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [counter, setCounter] = useState(updateInterval);
    const [volatilitySoundOff, setVolatilitySoundOff] = useState(localStorage.getItem('volatilitySoundOff') !== null);
    const [volatilityTriggerValue, setVolatilityTriggerValue] = useState(parseFloat(localStorage.getItem('volatilityTriggerValue') ||'0.01' ));

    useEffect(() => {
        updateTable();
        setInterval(
            () => { 
                setCounter(updateInterval); 
                updateTable(); }
            , updateInterval * 1000);

            if(!timer)
                timer = setInterval(()=>{setCounter(prev => prev-1)} , 1000);
            },[])

    const updateTable = ()=>{
        const dataService = new DataService();
        setIsLoading(true);
        dataService.getFinamStatus().then((status)=>{
            console.log(new Date(), status);
            setFinamStatus(status)
            dataService.getVolatility().then((response: IVolatilityData[])=>{
                setTableData(response);
                setIsLoading(false);
                let stopSound = localStorage.getItem('volatilitySoundOff') !== null;
                response.forEach(el => {
                    if(!stopSound && el.volatility.find(f=> parseFloat(f.value.replace(',', '.')) >= parseFloat(localStorage.getItem('volatilityTriggerValue') ||'0.01' )))
                    {
                        (new Audio("./assets/sounds/volatility.mp3")).play();
                        stopSound = true;
                    }
                });
            });
        });
    }

    const soundOffChanged = (target: React.FormEvent<HTMLInputElement>) => {
        const value = (target.target as HTMLInputElement).checked;
        console.log(volatilitySoundOff, value);
        setVolatilitySoundOff(value);
        if(value)
            localStorage.setItem('volatilitySoundOff', "1")
        else
            localStorage.removeItem('volatilitySoundOff')
    }

    const triggerValueChanged = (target: React.FormEvent<HTMLInputElement>) => {
        const value = parseFloat((target.target as HTMLInputElement).value);
        setVolatilityTriggerValue(value);
        localStorage.setItem('volatilityTriggerValue', value.toString())
    }

    return (
        visible ? 
            isLoading ? 
            <ProgressIndicator label="Loading..." description=""/> : 
            <>
                <table>
                    <tr style={{textAlign:'center'}}>
                        <td colSpan={3}>
                            <b>Таблица относительного размаха </b><br/><span id="finamStatus">({finamStatus}, автообновление страницы через {counter} сек.)</span>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <input type='number' min={0.01} max={100} step={0.01} value={volatilityTriggerValue} onChange={triggerValueChanged} name='cbVolatilityTriggerValue' style={{width:"50px"}}/> 
                            <label htmlFor="cbVolatilityTriggerValue">%&nbsp;Порог срабатывания уведомления</label>
                            <input type='checkbox' name='cbVolatilitySound' checked={volatilitySoundOff} onChange={soundOffChanged} /> 
                            <label htmlFor="cbVolatilitySound">&nbsp;Отключить звук уведомления</label> <br/>
                        </td>
                    </tr>
                    <tr>
                        <th>5 мин</th>
                        <th>15 мин</th>
                        <th>1 час</th>
                    </tr>
                    <tr>
                        <td id="volatilityM5" style={{borderRight:'1px solid black', padding:'3px', verticalAlign:'top'}}>
                            <table>
                            {
                                tableData?.filter(f=>f.timeFrame == "M5")[0]?.volatility.map((volatility:IVolatility) =>{
                                    return <tbody>
                                        <tr>
                                            <td style={{padding:'3px'}}>{volatility.value}%</td>
                                            <td style={{padding:'3px'}}><b>{volatility.ticker}</b></td>
                                            <td style={{padding:'3px'}}>{volatility.tickerName}</td>
                                            <td style={{padding:'3px'}}>{volatility.time}</td>
                                        </tr>
                                    </tbody>
                                })
                            }
                            </table>
                        </td>
                        <td id="volatilityM15" style={{borderRight:'1px solid black', padding:'3px', verticalAlign:'top'}}>
                            <table>
                            {
                                tableData!.filter(f=>f.timeFrame == "M15")[0]!.volatility.map((volatility:IVolatility) =>{
                                    return <tbody>
                                        <tr>
                                            <td style={{padding:'3px'}}>{volatility.value}%</td>
                                            <td style={{padding:'3px'}}><b>{volatility.ticker}</b></td>
                                            <td style={{padding:'3px'}}>{volatility.tickerName}</td>
                                            <td style={{padding:'3px'}}>{volatility.time}</td>
                                        </tr>
                                    </tbody>
                                })
                            }
                            </table>
                        </td>
                        <td id="volatilityH1" style={{borderRight:'1px solid black', padding:'3px', verticalAlign:'top'}}>
                            <table>
                            {
                                tableData!.filter(f=>f.timeFrame == "H1")[0]!.volatility.map((volatility:IVolatility) =>{
                                    return <tbody>
                                        <tr>
                                            <td style={{padding:'3px'}}>{volatility.value}%</td>
                                            <td style={{padding:'3px'}}><b>{volatility.ticker}</b></td>
                                            <td style={{padding:'3px'}}>{volatility.tickerName}</td>
                                            <td style={{padding:'3px'}}>{volatility.time}</td>
                                        </tr>
                                    </tbody>
                                })
                            }
                            </table>
                        </td>
                    </tr>
                </table>
            </>: <></>
    )
};

export default Volatility;