import React, { useState, useEffect } from 'react';
import { DataService } from "../dal";
import { IFilterData, IDictionary, IFilter } from "../dal/models";
import { initializeIcons } from '@fluentui/react/lib/Icons';

import { Dropdown, IDropdownStyles, IDropdownOption, TextField, PrimaryButton } from '@fluentui/react';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';


initializeIcons();

export interface IFiltersProps{
    onNewFilterValue: (filter:{}) => void;
    visible: boolean;
}

export const  Filters:React.FunctionComponent<IFiltersProps> =  ({onNewFilterValue, visible}) =>{
    const [optionsDDLFilter, setOptionsDDLFilter] = useState<{[key:string]:{options: IDropdownOption[], value:string, title:string }}>({});
    const [optionsDDLSorting, setOptionsDDLSorting] = useState<{[key:string]:{options: IDropdownOption[], value:string, title:string }}>({});
    const [optionsDDLAdditional, setOptionsDDLAdditional] = useState<{[key:string]:{options: IDropdownOption[], value:string, title:string }}>({});
    const dropdownStyles: Partial<IDropdownStyles> = {dropdown: { width: 300 }};
    const [isLoading, setIsLoading] = useState(true);
    const [lookupTicker, setLookupTicker] = React.useState("");

    useEffect(() => {
        const dataService = new DataService();
        dataService.getFiltersData().then((response: IFilterData)=>{
            setOptionsDDLFilter(prev => {
                let opt = response.Patterns.map( m => { return { key: m.Value, text: m.Title };});
                prev.Patterns = {
                    options: opt,
                    value: opt[0].key as  string,
                    title: "Pattern"
                };   
                opt = response.Countries.map( m => { return { key: m.Value, text: m.Title };} ).filter((v,i,a)=>a.findIndex(o=>o.key == v.key)===i);
                prev.Countries = {
                    options: opt,
                    value: opt[0].key as  string,
                    title: "Country"
                }; 
                opt = response.Exchanges.map( m => { return { key: m.Value, text: m.Title };} );
                prev.Exchanges = {
                    options: opt,
                    value: opt[0].key as  string,
                    title: "Exchange"
                }; 
                opt = response.VolumeRanges.map( m => { return { key: m.Value, text: m.Title };} );
                prev.VolumeRanges = {
                    options: opt,
                    value: opt[0].key as  string,
                    title: "Volume"
                }; 
                opt = response.VolumeAvgRanges.map( m => { return { key: m.Value, text: m.Title };} );
                prev.VolumeAvgRanges = {
                    options: opt,
                    value: opt[0].key as  string,
                    title: "Volume(rel)"
                }; 
                opt = response.MarketCapRanges.map( m => { return { key: m.Value, text: m.Title };} );
                prev.MarketCapRanges = {
                    options: opt,
                    value: opt[0].key as  string,
                    title: "MarketCap"
                }; 
                opt = response.PriceRanges.map( m => { return { key: m.Value, text: m.Title };} );
                prev.PriceRanges = {
                    options: opt,
                    value: opt[0].key as  string,
                    title: "Price"
                }; 
                opt = response.PERanges.map( m => { return { key: m.Value, text: m.Title };} );
                prev.PERanges = {
                    options: opt,
                    value: opt[0].key as  string,
                    title: "PE"
                }; 
                opt = response.PBRanges.map( m => { return { key: m.Value, text: m.Title };} );
                prev.PBRanges = {
                    options: opt,
                    value: opt[0].key as  string,
                    title: "PB"
                }; 
                opt = response.PSRanges.map( m => { return { key: m.Value, text: m.Title };} );
                prev.PSRanges = {
                    options: opt,
                    value: opt[0].key as  string,
                    title: "PS"
                }; 
                opt = response.TrendsRanges.map( m => { return { key: m.Value, text: m.Title };} );
                prev.TrendsRanges = {
                    options: opt,
                    value: opt[0].key as  string,
                    title: "Trend"
                }; 
                opt = response.CandlesRanges.map( m => { return { key: m.Value, text: m.Title };} );
                prev.CandlesRanges = {
                    options: opt,
                    value: opt[0].key as  string,
                    title: "Candles"
                }; 
                return prev; 
            }); 

            setOptionsDDLSorting(prev => {
                let opt = response.SortByValues.map( m => { return { key: m.Value, text: m.Title };});
                prev.SortByValues = {
                    options: opt,
                    value: opt[0].key as  string,
                    title: ""
                }; 
                return prev; 
            });

            setOptionsDDLAdditional(prev => {
                let opt = response.TopTypes.map( m => { return { key: m.Value, text: m.Title };});
                prev.TopTypes = {
                    options: opt,
                    value: opt[0].key as  string,
                    title: ""
                }; 
                return prev; 
            });

            setIsLoading(false);
        });
    }, []);

    const filter = (tickerName:string = ""):IFilter => {
            const filter: IFilter = {};
            Object.keys(optionsDDLFilter).forEach(key=>{
                filter[key] = [optionsDDLFilter[key].value];
            });
            Object.keys(optionsDDLSorting).forEach(key=>{
                filter[key] = [optionsDDLSorting[key].value];
            });
            Object.keys(optionsDDLAdditional).forEach(key=>{
                filter[key] = [optionsDDLAdditional[key].value];
            });
            if(tickerName)
                filter.TickersFilter = [tickerName];
            return filter;
    }

    useEffect(() => {
        onNewFilterValue(filter());
    },[optionsDDLFilter, optionsDDLSorting, optionsDDLAdditional]); 

    const onChangeFilter = React.useCallback(
    (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number, value?: string): void => {
        const ddlName = (event.target as HTMLElement).getAttribute('accessKey') as  string;
        setOptionsDDLFilter(prev => {
            prev[ddlName].value  = option?.key as  string; 
            return prev; 
        }); 
        onNewFilterValue(filter());
    },[]);

    const onChangeSorting = React.useCallback(
    (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number, value?: string): void => {
        const ddlName = (event.target as HTMLElement).getAttribute('accessKey') as  string;
        setOptionsDDLSorting(prev => {
            prev[ddlName].value  = option?.key as  string; 
            return prev; 
        }); 
        onNewFilterValue(filter());
    },[]);
    
    const  onChangeAdditional= React.useCallback(
        (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number, value?: string): void => {
            const ddlName = (event.target as HTMLElement).getAttribute('accessKey') as  string;
            setOptionsDDLAdditional(prev => {
                prev[ddlName].value  = option?.key as  string; 
                return prev; 
            }); 
            onNewFilterValue(filter());
    },[]);

    const handleLookupTickerChange =  React.useCallback((event:React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue:string | undefined) => {
        setLookupTicker(newValue || '');
    }, []);

    return (
        visible ?
        <>
            <section>
                <h3>Filters</h3>
                { isLoading ? 
                    <ProgressIndicator label="Loading..." description=""/> :
                    <div style={{"display":"flex", "flexWrap":"wrap" }}>
                        {
                        Object.keys(optionsDDLFilter).map((ddlKey,indxKey)=>{
                                return <div key={ddlKey+indxKey}>
                                    <Dropdown  
                                            label={optionsDDLFilter[ddlKey].title} 
                                            accessKey={ddlKey} 
                                            defaultSelectedKey={optionsDDLFilter[ddlKey].value} 
                                            options={optionsDDLFilter[ddlKey].options} 
                                            disabled={false} 
                                            styles={dropdownStyles} 
                                            onChange={onChangeFilter}/>
                                </div>
                            })
                        }
                    </div>
                }
            </section>
            <section style={{"display":"flex", "flexWrap":"wrap" }}>
                <section>
                    <h3>Sorting</h3>
                    { isLoading ? <></> :
                        <div style={{"display":"flex", "flexWrap":"wrap" }}>
                        {
                            Object.keys(optionsDDLSorting).map((ddlKey,indxKey)=>{
                                return <div key={ddlKey+indxKey}>
                                    <Dropdown  
                                            label={optionsDDLSorting[ddlKey].title} 
                                            accessKey={ddlKey} 
                                            defaultSelectedKey={optionsDDLSorting[ddlKey].value} 
                                            options={optionsDDLSorting[ddlKey].options} 
                                            disabled={false} 
                                            styles={dropdownStyles} 
                                            onChange={onChangeSorting}/>
                                </div>
                            })
                        }
                        </div>
                    }
                </section>
                <section>
                    <h3>Additional</h3>
                    { isLoading ? <></> :
                        <div style={{"display":"flex", "flexWrap":"wrap", columnGap:"10px", rowGap:"10px" }}>
                            {
                                Object.keys(optionsDDLAdditional).map((ddlKey,indxKey)=>{
                                    return <div key={ddlKey+indxKey}>
                                        <Dropdown  
                                                label={optionsDDLAdditional[ddlKey].title} 
                                                accessKey={ddlKey} 
                                                defaultSelectedKey={optionsDDLAdditional[ddlKey].value} 
                                                options={optionsDDLAdditional[ddlKey].options} 
                                                disabled={false} 
                                                styles={dropdownStyles} 
                                                onChange={onChangeAdditional}/>
                                    </div>
                                })
                            }
                            <TextField id={"tickerField"} value={lookupTicker} placeholder="Filter by ticker name" onChange={handleLookupTickerChange}/>
                            <PrimaryButton text="Find" onClick={() => onNewFilterValue(filter(lookupTicker))}  allowDisabledFocus disabled={!lookupTicker} />
                        </div>   
                    } 
                </section>
            </section>
            
        </> : <></>
    );
}


export default Filters;