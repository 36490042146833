import React, { useState, useEffect } from 'react';
import { DataService } from "../dal"
import { Panel, PanelType, PrimaryButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';

interface ISignOutProps {
    callBack: (tab:number) => void;
    open:boolean;
    callBackTab:number;
}

export const  SignOut:React.FunctionComponent<ISignOutProps> =  ({callBack, open, callBackTab}) =>{
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);

    useEffect(() => {
        if (open && !isOpen) {
            openPanel();
        }
    }, [open]);

    const signOutClick = ()=>{
        const dataService = new DataService();
        dataService.SignOut().then((response)=>{
            callBack(callBackTab);
            dismissPanel();
        });
    }

    const close = ()=> { 
        dismissPanel();
        callBack(callBackTab);
    }

    return (
        <Panel
            headerText="Sign out"
            isOpen={isOpen}
            closeButtonAriaLabel=""
            type={PanelType.custom}
            customWidth={'400px'}
            onDismiss={close}>
                <PrimaryButton text="Sing out" onClick={signOutClick} allowDisabledFocus disabled={false} />
        </Panel>
    )
};

export default SignOut;