import React, { useState, useEffect } from 'react';
import { DataService } from "../dal"
import { IRobotsGroupDataRecord, IRobotGroupData } from "../dal/models";
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
import { DatePicker, defaultDatePickerStrings } from '@fluentui/react';

interface IRobotsProps {
    visible: boolean;
}

export const  Robots:React.FunctionComponent<IRobotsProps> =  ({visible}) =>{
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState<IRobotGroupData[]>([{robotID:0, robotName:"", patternName:"", Profit:"", ProfitYear:"", Drawdown:"", reportData:[]}]);
    const [reportStartDay, setReportStartDay] = React.useState<Date | undefined>(new Date(new Date().setFullYear(new Date().getFullYear() - 1)));

    useEffect(() => {
        updateTable();
    },[reportStartDay]);

    const updateTable = ()=>{
        setTimeout (()=>{
            const dataService = new DataService();
            setIsLoading(true);
            dataService.getRobotGroupReport(reportStartDay!.toISOString().split('T')[0]).then((response: IRobotGroupData[])=>{
                console.log(response);
                setTableData(response);
                setIsLoading(false);
            });
        },1);
    }

    const income = (robotID:number):string=>{
        return tableData?.filter(f=>f.robotID === robotID)[0]?.Profit;
    }

    const incomeColor = (robotID:number):string=>{
        return tableData?.filter(f=>f.robotID === robotID)[0]?.Profit.indexOf('-') == 0 ? "red" : "green";
    }

    const incomeYear = (robotID:number):string=>{
        return tableData?.filter(f=>f.robotID === robotID)[0]?.ProfitYear;
    }

    const maxDrawdown = (robotID:number):string=>{
        return tableData?.filter(f=>f.robotID === robotID)[0]?.Drawdown;
    }

    const getID = () =>{
            var d = new Date().getTime();//Timestamp
            var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16;//random number between 0 and 16
                if(d > 0){//Use timestamp until depleted
                    r = (d + r)%16 | 0;
                    d = Math.floor(d/16);
                } else {//Use microseconds since page-load if supported
                    r = (d2 + r)%16 | 0;
                    d2 = Math.floor(d2/16);
                }
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
    }
    
    return (
        visible ? isLoading ? 
        <ProgressIndicator label="Loading..." description=""/> : 
        <>
            <table>
                <thead>
                    <tr style={{textAlign:'center'}}>
                        <th colSpan={5}>
                            История работы роботов
                        </th>
                    </tr>
                    <tr>
                        <th colSpan={5}>
                        <DatePicker
                            firstDayOfWeek={1}
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            onSelectDate={(date: Date | null | undefined) => {
                                if(date){
                                    setReportStartDay(date);
                                }
                            }}
                            value={reportStartDay}/>
                        </th>
                    </tr>
                    <tr>
                        {
                            tableData.map((robot, indx) =>  <td key={getID()}><b>{robot?.robotName}</b></td>)
                        }
                        <td><b>Робот 4</b> (платный)</td>
                        <td><b>Робот 5</b> (платный)</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {
                            tableData.map((robot, indx) =>  
                                <td style={{borderRight:'1px solid black', padding:'3px', verticalAlign:'top'}} key={getID()}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td colSpan={5}>
                                                    {robot?.patternName}<br/>
                                                    Доход за период: <span  style={{fontWeight:'bold', padding:'3px', 
                                                                                    float:'inherit',
                                                                                    color: incomeColor(robot.robotID) }}> 
                                                    {income(robot.robotID)}</span><br/>
                                                    Годовой доход: <span  style={{fontWeight:'bold', padding:'3px', 
                                                                                    float:'inherit',
                                                                                    color: incomeColor(robot.robotID)}}> 
                                                    {incomeYear(robot.robotID)}</span><br/>
                                                    Максимальная просадка: <span style={{fontWeight:'bold' ,padding:'3px', color: "red", float:'inherit'}}> {maxDrawdown(robot.robotID)}</span>
                                                </td>
                                            </tr>
                                            {
                                                robot?.reportData.map((robotRecord:IRobotsGroupDataRecord) =>{
                                                    return <tbody key={getID()}>
                                                        <tr>
                                                            <td style={{padding:'3px'}}>
                                                                <b>{robotRecord.ticker}</b><br/>
                                                                {robotRecord.eventDirection}
                                                            </td>
                                                            <td style={{padding:'3px'}}>
                                                                {robotRecord.enterDate.toString().split('T')[0]} {robotRecord.enterDate.toString().split('T').reverse()[0].split('.')[0].split('+')[0]}<br/>
                                                                {robotRecord.exitDate ? robotRecord.exitDate.toString().split('T')[0] + " " + robotRecord.exitDate.toString().split('T').reverse()[0].split('.')[0].split('+')[0] : <br/>}
                                                            </td>
                                                            <td style={{padding:'3px', textAlign:'right'}}>
                                                                {robotRecord.enterPrice}$ <br/>
                                                                {robotRecord.exitDate ? robotRecord.exitPrice + "$" : <br/>}
                                                            </td>
                                                            <td style={{padding:'3px', color: robotRecord.profit.indexOf('-') == 0 ? "red" : "green"}}>
                                                                {robotRecord.exitDate ? <b>{robotRecord.profit}</b> : ''}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </td>
                            )
                        }

                        <td style={{borderRight:'1px solid black', padding:'3px', verticalAlign:'top'}}>В процессе разработки!</td>
                        <td style={{borderRight:'1px solid black', padding:'3px', verticalAlign:'top'}}>В процессе разработки!</td>
                    </tr>
                </tbody>
            </table>
        </>: <></>
    )
};

export default Robots;