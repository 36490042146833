//import Cookies from 'universal-cookie';
import { IAuthData } from "./models"

export default class Helper {
    private static localStoreAuthKey:string = "_insignum";

    public static GetAuthData():IAuthData {
        const dataStr = localStorage.getItem(this.localStoreAuthKey) as string;
        const data = dataStr ?  JSON.parse(dataStr) : {Token: "", Name:""} as IAuthData;
        return data;
    }

    public static SetAuthData(data:IAuthData) {
        localStorage.setItem(this.localStoreAuthKey, JSON.stringify(data));
    }

    public static ClearAuthData(){
        localStorage.removeItem(this.localStoreAuthKey);
    }

    public static IsLoggenIn(): boolean{
        const storeData = this.GetAuthData();
        const isLoggenIn = storeData && storeData.ID !== "00000000-0000-0000-0000-000000000000";
        return isLoggenIn;
    }

    public static EnsureHTTPS():Promise<void>{
        return new Promise<void>((resolve, reject) => {
            if (window.location.protocol == 'http:' && ( window.location.href.indexOf('.com') > -1 || window.location.href.indexOf('.ru') >-1)) 
                window.location.href =window.location.href.replace('http:', 'https:');
            else
                resolve();
        });
    }
}

