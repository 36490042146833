import ReactDOM from 'react-dom/client';
import { useState, useEffect, Component } from 'react';
import './index.css';
import { TopMenu, Filters, Content, SignIn, SignOut, About, Volatility, Robots } from './components';
import { IMenuItem, IFilter } from "./dal/models"
import  {Helper}  from "./dal"




export namespace Insignum {

  Helper.EnsureHTTPS().then(()=> {

    const root = ReactDOM.createRoot(
      document.getElementById('root') as HTMLElement
    );
  
    const Wizard = () => {
      const [filter, setFilter] = useState<IFilter>({});
      const [openedTab, setOpenedTab] = useState<number>(0);
      const [openedPopup, setOpenedPopup] = useState<number>(0);
      const [menuItems, setMenuItems] = useState<IMenuItem[]>([]);
  
      const filterChanged = (filter: IFilter = {}) => {
        filter["IndexRange"] = ["0_14"];
        filter["ID"] = Math.random();
        setFilter(filter);
      }
   
      const refreshPage = (tab:number) => {
        filterChanged();
        setOpenedTab(tab || 0);
        setOpenedPopup(0);
        setMenuItems([
          {
            headerText:"Main", 
            itemKey: "main", 
            content: <></>,
            hidden: false,
            click: ()=>{ 
              setOpenedTab(0);
             }
          },
          {
            headerText:"Volatility", 
            itemKey: "volatility", 
            content: <></>,
            hidden: false,
            click: ()=>{ 
              setOpenedTab(1);
             }
          },
          {
            headerText:"Robots", 
            itemKey: "robots", 
            content: <></>,
            hidden: false,
            click: ()=>{ 
              setOpenedTab(2);
             }
          },
          {
            headerText:"About", 
            itemKey: "about", 
            content: <></>,
            hidden: false,
            click: ()=>{ setOpenedPopup(1);}
          },
          {
            headerText:"Sign In", 
            itemKey: "signIn", 
            content: <></>,
            hidden: Helper.IsLoggenIn(),
            click: ()=>{ setOpenedPopup(2);}
          },
          {
            headerText:"Sign Out", 
            itemKey: "signOut", 
            content: <></>,
            hidden: !Helper.IsLoggenIn(),
            click: ()=>{ setOpenedPopup(3);}
          }
        ]);
      }
  
      useEffect(() => {
        refreshPage(0);
        if(window.location.search.toLowerCase().indexOf('volatility') > 0)
          setOpenedTab(1); 
        else if(window.location.search.toLowerCase().indexOf('robots') > 0)
          setOpenedTab(2); 
      },[]); 
  
      return (
        <div className="ins-app">
          <header className="ins-app-header">
            <nav id="ins-menu">
                <TopMenu items={menuItems} selectedTab={openedTab}></TopMenu>
              </nav>
          </header>
          <section>
              <Filters  onNewFilterValue={filterChanged} visible={openedTab <= 0}></Filters>
              <section style={{marginTop:"10px"}}>
                <Content filter={filter} visible={openedTab === 0}/>
              </section>
          </section>
          <section>
              <Volatility visible={openedTab === 1}></Volatility>
          </section>
          <section>
              <Robots visible={openedTab === 2}></Robots>
          </section>
          <section>
            <SignIn callBack={refreshPage} open={openedPopup === 2} callBackTab={openedTab}/>
          </section>
          <section>
            <SignOut callBack={refreshPage} open={openedPopup === 3} callBackTab={openedTab}/>
          </section>
          <section>
            <About callBack={refreshPage} open={openedPopup === 1} callBackTab={openedTab}/>
          </section>
          <footer>
              <p>&copy; 2021 - {new Date().getFullYear()} </p>
          </footer>
        </div>
      )
    }

    root.render(
      <Wizard/>
    );

  });
}


//var express = require('express');
//var session = require('express-session');

//var app = express();

//app.use(session({secret: "Shh, its a secret!"}));

//app.get('/api', function(req:any, res:any){
  // if(req.session.page_views){
   //   req.session.page_views++;
  //    res.send("You visited this page " + req.session.page_views + " times");
  // } else {
   //   req.session.page_views = 1;
    //  res.send("Welcome to this page for the first time!");
 //  }
//});
//app.listen(3001);