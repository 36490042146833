import React, { useState, useEffect } from 'react';
import { Pivot, PivotItem } from '@fluentui/react';
import {IMenuItem} from "../dal/models"

interface IMenuProps {
    userIsActive?:boolean,
    adminAccess?: boolean,
    items: IMenuItem[];
    onLinkClick?: (key:string) => void;
    selectedTab:number;
}

export const  TopMenu:React.FunctionComponent<IMenuProps> =  ({items, onLinkClick, selectedTab}) =>{
    const [selectedKey, setSelectedKey] = useState<string>(items[selectedTab]?.itemKey || "main");
    const handleLinkClick = (item?: PivotItem) => {
        if (item) {
            const indx = +item.props.itemID!;
            if(typeof items[indx].click ===  'function')
                items[indx].click!();

            setSelectedKey(item.props.itemKey!);
            if(onLinkClick) 
                onLinkClick!(item.props.itemKey!);
        }
    };

    useEffect(() => {
        setSelectedKey(items[selectedTab]?.itemKey);
    },[items, selectedTab]); 

    return (
        <Pivot aria-label="" linkSize="large" linkFormat='links' selectedKey={selectedKey} onLinkClick={handleLinkClick} headersOnly={false} >
            {
                items.map( (m, i) => <PivotItem 
                                        key={m.itemKey} 
                                        headerText={m.headerText} 
                                        itemKey={m.itemKey} 
                                        hidden={true} 
                                        itemID={i.toString()}
                                        headerButtonProps={{
                                            'style': {display: m.hidden ? 'none': ''}
                                        }}> 
                                </PivotItem> )
            }
        </Pivot>
    );
}

export default TopMenu;