import React, { useEffect } from 'react';
import { DataService } from "../dal"
import { Panel, Label, TextField, PrimaryButton, IconButton, Spinner, SpinnerSize, Pivot, PivotItem, IIconProps, PanelType, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';

interface ISignProps {
    callBack: (tab:number) => void;
    open:boolean;
    callBackTab:number;
}

export const  SignIn:React.FunctionComponent<ISignProps> =  ({callBack, open, callBackTab}) =>{
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [login, setLogin] = React.useState("");
    const [pswd, setPSWD] = React.useState("");
    const [captcha, setCaptcha] = React.useState("");
    const [answer, setAnswer] = React.useState("");
    const [errorSignIn, setErrorSignIn] = React.useState("");
    const [errorSignUp, setErrorSignUp] = React.useState("");
    const [inProgress, setInProgress] = React.useState(false);

    const [isNonWhiteSpaceErr, setIsNonWhiteSpaceErr] = React.useState(false);
    const [isContainsNumberErr, setIsContainsNumberErr] = React.useState(false);
    const [isContainsUppercaseErr, setIsContainsUppercaseErr] = React.useState(false);
    const [isContainsSymbolErr, setIsContainsSymbolErr] = React.useState(false);
    const [isValidLengthErr, setIsValidLengthErr] = React.useState(false);
    const [isValidLoginErr, setIsValidLoginErr] = React.useState(false);
    const [isNonWhiteSpaceLoginErr, setIsNonWhiteSpaceLoginErr] = React.useState(false);

    const lineStyle = {marginTop:"10px", display:"flex", columnGap:"10px"}
    const reloadIcon: IIconProps = { iconName: 'Refresh' };

    useEffect(() => {
        if (open && !isOpen) {
            openPanel();
            setLogin('');
            setPSWD('');
            reloadCaptcha();
        }
    }, [open]);

    const signUpClick = ()=>{
        const dataService = new DataService();
        if(login && pswd && answer){
            setErrorSignUp('');
            setInProgress(true);
            dataService.SignUp(login, pswd, answer).then((response)=>{
                if(response.Error){
                    setErrorSignUp(response.Error);
                    setAnswer('');
                    reloadCaptcha();
                }
                else {
                    callBack(callBackTab);
                    dismissPanel();
                }
            })
            .catch(()=>{setErrorSignIn('Error');})
            .finally(()=>{setInProgress(false)});
        }
        else{
            setErrorSignUp('Fill in the required fields');
        }
    }

    const reloadCaptcha = () => {
        const dataService = new DataService();
        setInProgress(true)
        dataService.Captcha().then((response)=>{
            setInProgress(false)
            setCaptcha(response);
        });
    }

    const signInClick = ()=>{
        const dataService = new DataService();
        if(login && pswd){
            setErrorSignIn('');
            setInProgress(true)
            dataService.SignIn(login, pswd).then((response)=>{
                if(response.Error){
                    setErrorSignIn(response.Error);
                    setPSWD('');
                }
                else {
                    callBack(callBackTab);
                    dismissPanel();
                }
            })
            .catch(()=>{setErrorSignIn('Error');})
            .finally(()=>{setInProgress(false)});
        }
        else{
            setErrorSignIn('Fill in the required fields');
        }
    }

    const close = ()=> { 
        dismissPanel();
        callBack(callBackTab);
    }

    const handleLoginChange =  React.useCallback((event:React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue:string | undefined) => {
        setLogin(newValue || '');
        setErrorSignUp('');
        setErrorSignIn('');
    }, []);

    const handlePSWDChange =  React.useCallback((event:React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue:string | undefined) => {
        setPSWD(newValue || '');
        setErrorSignUp('');
        setErrorSignIn('');
    }, []);

    const handleAnswerChange =  React.useCallback((event:React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue:string | undefined) => {
        setAnswer(newValue || '');
        setErrorSignUp('');
        setErrorSignIn('');
    }, []);

    const getLoginErrorMessage = (value: string): string => {
        const isValidLength = /^.{4,10}$/;
        const isNonWhiteSpace = /^\S*$/;
        setIsValidLoginErr(value.length !== 0 && !isValidLength.test(value));
        setIsNonWhiteSpaceLoginErr(value.length !== 0 && !isNonWhiteSpace.test(value));
        return "";
    };

    const getPswdErrorMessage = (value: string): any => {
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

        const isNonWhiteSpace = /^\S*$/;
        const isContainsUppercase = /^(?=.*[A-Z]).*$/;
        const isContainsLowercase = /^(?=.*[a-z]).*$/;
        const isContainsNumber = /^(?=.*[0-9]).*$/;
        const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
        const isValidLength = /^.{8,16}$/;

        setIsNonWhiteSpaceErr(value.length !== 0 && !isNonWhiteSpace.test(value));
        setIsContainsNumberErr(value.length !== 0 && !isContainsNumber.test(value));
        setIsContainsUppercaseErr(value.length !== 0 && (!isContainsUppercase.test(value) || !isContainsLowercase.test(value)));
        setIsContainsSymbolErr(value.length !== 0 && !isContainsSymbol.test(value));
        setIsValidLengthErr(value.length !== 0 && !isValidLength.test(value));

        return <></>
    };

    const errStyle = {color:"red", marginTop:"5px", fontSize:"12px"};

    return (
        <Panel
            headerText=""
            isOpen={isOpen}
            closeButtonAriaLabel="Close"
            type={PanelType.custom}
            customWidth={'400px'}
            onDismiss={close}>
                <Pivot aria-label="" linkSize="large" linkFormat='links' headersOnly={false}>
                    <PivotItem headerText="Sign In">
                        <Label htmlFor={"loginField"} required>Login</Label>
                        <TextField id={"loginField"} placeholder="Login name"  onChange={handleLoginChange}/>
                        <Label htmlFor={"passwordField"} required>Password</Label>
                        <TextField id={"passwordField"} placeholder='Password' type='password' value={pswd}  onChange={handlePSWDChange}/>
                        <div style={lineStyle}>
                            <PrimaryButton text="Sing in" onClick={signInClick} allowDisabledFocus disabled={inProgress} />
                            { inProgress && <Spinner size={SpinnerSize.medium}/> }
                        </div>
                        <p style={{color:'red', fontWeight:'bold'}}>{errorSignIn}</p>
                    </PivotItem>
                    <PivotItem headerText="Sign Up">
                        <Label htmlFor={"loginField"} required>Login</Label>
                        <TextField id={"loginField"} placeholder="Login name"  onChange={handleLoginChange} onGetErrorMessage={getLoginErrorMessage}/>
                        <Label htmlFor={"passwordField"} required>Password</Label>
                        <TextField id={"passwordField"} placeholder='Password' type='password' value={pswd}  onChange={handlePSWDChange} onGetErrorMessage={getPswdErrorMessage}/>
                        <Label htmlFor={"captchaField"} required>Captcha</Label>
                        <div style={lineStyle}>
                            <img style={{height:"30px"}} alt="Captcha" title="Captcha" src={"data:image/bmp;base64,"+captcha}/>
                            <IconButton iconProps={reloadIcon} aria-label="Emoji" onClick={reloadCaptcha} disabled={inProgress} />
                            <TextField id={"captchaField"} placeholder='Answer' type='number' value={answer}  onChange={handleAnswerChange}/>
                        </div>
                        <div style={lineStyle}>
                            <PrimaryButton text="Sing up" onClick={signUpClick} allowDisabledFocus disabled={inProgress || isNonWhiteSpaceErr || isContainsNumberErr || isContainsUppercaseErr || isContainsSymbolErr || isValidLengthErr || isValidLoginErr || isNonWhiteSpaceLoginErr} />
                            { inProgress && <Spinner size={SpinnerSize.medium}/> }
                        </div>
                        <p style={{color:'red', fontWeight:'bold'}}>{errorSignUp}</p>

                        {isNonWhiteSpaceErr && <><Text style={errStyle}>Password must not contain any whitespace.</Text><br/></>} 
                        {isContainsNumberErr && <><Text style={errStyle}>Password must containat least one number.</Text><br/></>} 
                        {isContainsUppercaseErr && <><Text style={errStyle}>Password must contain at least one uppercase, one lowercase and one numeric character."</Text><br/></>} 
                        {isContainsSymbolErr && <><Text style={errStyle}>Password must contain at least one special character.</Text><br/></>} 
                        {isValidLengthErr && <><Text style={errStyle}>Length of Password must be between 8 to 16 characters.</Text><br/></>} 
                        {isValidLoginErr && <><Text style={errStyle}>Length of Login must be between 4 to 10 characters.</Text><br/></>} 
                        {isNonWhiteSpaceLoginErr && <><Text style={errStyle}>Login name must not contain any whitespace.</Text><br/></>} 
                    </PivotItem>
                </Pivot>
        </Panel>
    )
};

export default SignIn;
